import { createContext, ReactNode, useEffect, useState } from 'react';
import api from '../api';
import useThrowAsyncError from '../hooks/useThrowAsyncError';

export type FeatureToggles = {
  SMARTSCHOOL_LOGIN?: boolean;
  BLINK_LOGIN?: boolean;
  PRIVACY_POLICY?: boolean;
  SHOW_ALTERNATE_STUDENT_PLATFORM_LINK?: boolean;
  GROUP_MGMT?: boolean;
  MATERIAL_ASSIGNMENTS?: boolean;
  BOOKWIDGET_USER_MATERIAL?: boolean;
  SHOW_SCORE_DEBUG?: boolean;
  SHOW_SCORE?: boolean;
};

const FeatureTogglesContext = createContext<FeatureToggles>({});

export default FeatureTogglesContext;

type FeatureToggleResponse = {
  name: string;
  enabled: boolean;
}[];

export function FeatureTogglesContextProvider({
  studioGroup,
  children,
}: {
  studioGroup: StudioGroup;
  children: ReactNode;
}): JSX.Element {
  const [featureToggles, setFeatureToggles] = useState<FeatureToggles>({});

  const handleError = useThrowAsyncError();

  useEffect(() => {
    async function getFeatureToggles(): Promise<void> {
      const { data } = await api.get<FeatureToggleResponse>('/feature-toggles', {
        params: {
          group: studioGroup,
        },
        skipAuthorization: true,
      });

      const fts: FeatureToggles = data.reduce(
        (toggles, currentToggle) => ({
          ...toggles,
          [currentToggle.name]: currentToggle.enabled,
        }),
        {},
      );

      setFeatureToggles(fts);
    }

    getFeatureToggles().catch(handleError);
  }, [studioGroup, handleError]);

  return <FeatureTogglesContext.Provider value={featureToggles}>{children}</FeatureTogglesContext.Provider>;
}
